import ParseJwt from '@/utils/ParseJwt'
import { defineStore } from 'pinia'
import { computed, onMounted, reactive, ref, watch } from 'vue'

export const useIdentityStore = defineStore('IdentityStore', () => {
  const Identity: any = reactive({
    token: '',
    email: '',
    exp: 0,
    name: '',
    company: '',
    position: '',
    is_api_available: false,
    is_confirmed: false,
    is_unsub_discount_applied: false,
    is_new: false,
    is_paid: false,
    is_name_search_enabled: false,
    test_groups: null,
    oauth_provider: null,
    popups: {},
    sub_info: {},
    old_stripe_sub: {},
    surveys: {},
    user_id: '',
    credits: {}
  })
  const EmptyIdentity = Object.assign({}, Identity)

  const getIdentityIsAuthorized = computed(() => {
    return Identity.token.length > 0
  })
  const getIdentityToken = computed(() => {
    return Identity.token
  })
  const getIdentityEmail = computed(() => {
    return Identity.email
  })
  const getIdentityFullName = computed(() => {
    return Identity.name != null && Identity.name.length > 0 ? Identity.name : Identity.email
  })

  const getIdentityCompany = computed(() => {
    return Identity.company
  })
  const getIdentityPosition = computed(() => {
    return Identity.position
  })
  const getIdentityIsApiAvailable = computed(() => {
    return Identity.is_api_available
  })
  const getIdentityCredits = computed(() => {
    return Identity.credits
  })
  const getIdentitySubscription = computed(() => {
    return Identity.sub_info
  })
  const getIdentityOldSubscription = computed(() => {
    return Identity.old_stripe_sub
  })
  const getIdentityShowIntroSurvey = computed(() => {
    return Identity.surveys.show_intro_survey
  })
  const getIdentityShowDiscountPopup = computed(() => {
    return Identity.popups?.discount_alert
  })
  const getIdentityShowDeletePopup = computed(() => {
    return Identity.popups?.deleted_account_alert
  })
  const getIdentityShowPopup = computed(() => {
    return getIdentityShowDeletePopup.value?.show
  })
  const getIdentityID = computed(() => {
    return Identity.user_id
  })
  const getIdentityIsNew = computed(() => {
    return Identity.is_new
  })
  const getIdentitySignUpType = computed(() => {
    return Identity.oauth_provider ?? 'email'
  })
  const getIdentityTestGroups = computed(() => {
    return Identity.test_groups
  })
  const getIdentityIsUnsubDiscountApplied = computed(() => {
    return Identity.is_unsub_discount_applied
  })
  const getIdentityIsNameSearchEnabled = computed(() => {
    return Identity.is_name_search_enabled
  })

  function setIdentityIsNew(): void {
    Identity.is_new = false
  }

  function setIdentity(token: string): void {
    const decoded = ParseJwt(token)

    for (const key in decoded) {
      if (key in Identity) {
        Identity[key as keyof typeof Identity] = decoded[key as keyof typeof decoded]
      }
    }

    Identity.token = token

    if (getIdentityOldSubscription.value != null) {
      finalDate.value = getTimerTime()
      // tickTimer()
      function getTimerTime(): number {
        console.log('showTimer0', showTimer.value)
        const time = getIdentityOldSubscription.value?.expires_on // '27.01.2025'//
        if (time != null) {
          const date = time.split('.').reverse().join('-') + 'T00:00:00'
          showTimer.value = true
          console.log('showTimer', showTimer.value)
          return new Date(date).getTime()
        }
        showTimer.value = false
        return new Date().getTime()
      }
    } else {
      localStorage.setItem('_xr_newsub_modal', 'false')
    }
    // new sub action
  }
  /// //new sub action//////////////////////
  const finalDate = ref(0)

  const showTimer = ref(false)

  const newSubShowTimer = computed(() => {
    return showTimer.value
  })
  const newSubTimeLeft = computed(() => {
    return finalDate.value
  })

  /// ///////////////////////////////////////////////////
  function setBusinessInfo(info: any): void {
    Identity.position = info.position
    Identity.company = info.company
  }

  let isClearingIdentity = false
  function clearIdentity(): void {
    isClearingIdentity = true
    for (const key in Identity) {
      Identity[key] = EmptyIdentity[key]
    }
    setTimeout(() => { isClearingIdentity = false }, 10)
  }
  function logOutIdentity(): void {
    clearIdentity()
    setTimeout(() => {
      localStorage.removeItem('_xray')
      window.location.reload()
    }, 100)
  }

  // Local storage savenavButtonClick

  watch(() => Identity, (newValue, oldValue) => {
    if (!isClearingIdentity) saveIdentityToLocalStorage()
  }, { deep: true })

  function saveIdentityToLocalStorage(): void {
    const savedIdentity = JSON.stringify(Identity)
    localStorage.setItem('_xray', savedIdentity)
  }

  function getIdentityFromLocalStorage(): void {
    const identityInLocalStorage = localStorage.getItem('_xray') ?? null
    if (identityInLocalStorage !== null) {
      const parsedIdentity = JSON.parse(identityInLocalStorage)

      for (const key in parsedIdentity) {
        if (key in Identity) {
          Identity[key] = parsedIdentity[key]
        }
      }
      //  tickTimer()
    }
  }
  onMounted(() => {
    getIdentityFromLocalStorage()
  })

  return {
    getIdentityIsAuthorized,
    getIdentityToken,
    getIdentityEmail,
    getIdentityFullName,
    getIdentityTestGroups,
    getIdentityCompany,
    getIdentityPosition,
    getIdentityIsApiAvailable,
    getIdentityCredits,
    getIdentitySubscription,
    getIdentityShowIntroSurvey,
    getIdentityShowPopup,
    getIdentityShowDeletePopup,
    getIdentityShowDiscountPopup,
    getIdentityID,
    getIdentitySignUpType,
    getIdentityIsNew,
    getIdentityIsUnsubDiscountApplied,
    getIdentityOldSubscription,
    getIdentityIsNameSearchEnabled,
    newSubShowTimer,
    newSubTimeLeft,
    setBusinessInfo,
    setIdentity,
    setIdentityIsNew,
    logOutIdentity
  }
})
