import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm'
import { createHead, useHead, useSeoMeta } from '@unhead/vue'

// import './style.scss'
import App from './App.vue'
import router from '@/router/router'
import { createPinia, storeToRefs } from 'pinia'
import { useIdentityStore } from '@/store/identityStore'
import gTagPushPageEvent from './utils/GoogleTag/gTagPushPageEvent'

const pinia = createPinia()
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const app = createApp(App)
const head = createHead()

function isCookiesAllowed (): boolean {
  const cookies = localStorage.getItem('_xr_cookies')

  if (cookies != null) {
    return cookies === 'true'
  }
  return false
}

app
  .use(pinia)
  .use(router)
  .use(head)

  .use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID,
      enabled: isCookiesAllowed(),
      debug: false, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router // Pass the router instance to automatically sync with router (optional)
    })
  )
  .mount('#app')

async function validateuser (): Promise<void> {
  const { default: authGetValidate } = await import('@/api/auth/authGetValidate')
  const result = await authGetValidate()
  if (result.status === 200) {
    setIdentity(result.body.token as string)
    sessionStorage.setItem('_xr_uval', 'true')
  } else {
    logOutIdentity()
    sessionStorage.setItem('_xr_uval', 'false')
  }
}
const IdentityStore = useIdentityStore()
const { getIdentityIsAuthorized, getIdentityID } = storeToRefs(IdentityStore)
const { setIdentity, logOutIdentity } = IdentityStore
if (getIdentityIsAuthorized.value) {
  void validateuser()
}
router.beforeEach(async (to, from) => {
  const { meta }: any = to
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Object.keys(meta).forEach((key) => {
    if (typeof meta[key] === 'function') {
      meta[key] = meta[key](to)
    }
  })

  interface Head {
    title: string
    meta: Array<{ name: string, content: string }>
    link?: Array<{ rel: string, href: string, key: string }>
  }
  const head: Head = {
    title: to.meta.title as string,
    meta: [{ name: 'description', content: to.meta.description as string }],
    link: [
      {
        rel: 'canonical',
        href: import.meta.env.VITE_API_HOST + to.path,
        key: 'canonical'
      }
    ]
  }
  if (to.name === 'Not found') {
    delete head.link
  }
  const isBeta = import.meta.env.VITE_API_HOST.includes('beta') as boolean
  const isProd = import.meta.env.VITE_API_HOST === 'https://x-ray.contact'
  if (isBeta) {
    head.meta.push({ name: 'robots', content: 'noindex' })
  } else if (to.meta.noIndex === true) {
    head.meta.push({ name: 'robots', content: 'noindex' })
  } else {
    const index = document.querySelector("[name='robots']")
    if (index != null) {
      index.remove()
    }
  }

  useHead(head)
  const xrImg = new URL('/src/theme/icons/main_logo_blue.svg', import.meta.url).href
  const seoMeta = {
    ogTitle: to.meta.title as string,
    ogDescription: to.meta.description as string,
    ogImage: xrImg,
    ogUrl: import.meta.env.VITE_API_HOST + to.path
  }
  useSeoMeta(seoMeta)

  const isPlatformPage = to.meta.isPlatform as boolean

  if (to.meta.isAdmin === true) {
    const { default: validateAdmin } = await import('@/api/auth/authGetValidateAdmin')
    const result = await validateAdmin()

    if (result.status !== 200) return { name: 'Not found' }
  }
  if (to.name === 'TG-bot Search') {
    if (isProd) return { name: 'Not found' }
  }
  if (isPlatformPage && !getIdentityIsAuthorized.value) {
    return { name: 'Log in' }
  }
  if (to.name === 'Log in' || to.name === 'Sign up') {
    if (getIdentityIsAuthorized.value) return { name: 'Platform' }
  }

  if (getIdentityIsAuthorized.value) gTagPushPageEvent(getIdentityID.value as string)
})
