/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'
import { firebaseConfig } from './firebase.config'

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(app)

// Handle service worker messages
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', async (event): Promise<void> => {
    console.log('Received message from service worker:', event.data)

    if (event.data?.type === 'NOTIFICATION_CLICKED' && event.data?.notificationId != null) {
      try {
        console.log('Marking notification as read:', event.data.notificationId)
        const { useNotificationsService } = await import('@/services/notificationsService')
        const notificationsService = useNotificationsService()
        await notificationsService.markNotificationsAsRead([event.data.notificationId])
        console.log('Successfully marked notification as read')
        await notificationsService.getNotifications() // Refresh the notifications list
      } catch (error) {
        console.error('Failed to mark notification as read:', error)
      }
    }
  })
}

// Initialize Firebase messaging without requesting permission
export const initializeFirebaseMessagingWithoutPermission = async (): Promise<any> => {
  try {
    // First check if messaging is supported in this browser
    const isFCMSupported = await isSupported()
    if (!isFCMSupported) {
      console.log('Firebase messaging is not supported')
      return null
    }

    // Check if service worker is registered
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js')
        console.log('Service Worker registered with scope:', registration.scope)
      } catch (err) {
        console.error('Service Worker registration failed:', err)
        throw new Error('Failed to register service worker')
      }
    } else {
      console.error('Service Workers not supported')
      return null
    }

    // If we already have permission, get the token
    if (Notification.permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: 'BB9o0bwdAykipQSI4Ni5vlt8ACFZ6-rD8jjjXyamymVUNU4Ar0SZ0WInjaQ9s9FSLpPu8_6NFjHFfr1bAlruriw'
      })

      if (token) {
        const { useNotificationsService } = await import('@/services/notificationsService')
        const notificationsService = useNotificationsService()

        try {
          await notificationsService.registerPushToken(token)
          console.log('Successfully registered push token')
          return token
        } catch (error) {
          console.error('Error registering token with backend:', error)
          if (error instanceof Error && !error.message.includes('403')) {
            await notificationsService.deletePushToken(token)
          }
          return null
        }
      }
    }
    return null
  } catch (error) {
    console.error('Error initializing Firebase messaging:', error)
    return null
  }
}

// Request permission and initialize Firebase messaging
export const initializeFirebaseMessaging = async (): Promise<any> => {
  try {
    // Request permission - this should only be called in response to a user gesture
    console.log('Requesting notification permission...')
    const permission = await Notification.requestPermission()
    console.log('Permission result:', permission)

    if (permission === 'granted') {
      console.log('Getting Firebase token...')
      const token = await getToken(messaging, {
        vapidKey: 'BB9o0bwdAykipQSI4Ni5vlt8ACFZ6-rD8jjjXyamymVUNU4Ar0SZ0WInjaQ9s9FSLpPu8_6NFjHFfr1bAlruriw'
      })

      if (token) {
        console.log('Got Firebase token, registering with backend...')
        const { useNotificationsService } = await import('@/services/notificationsService')
        const notificationsService = useNotificationsService()

        try {
          await notificationsService.registerPushToken(token)
          console.log('Successfully registered push token with backend')
          return token
        } catch (error) {
          console.error('Error registering token with backend:', error)
          if (error instanceof Error && !error.message.includes('403')) {
            console.log('Deleting failed token...')
            await notificationsService.deletePushToken(token)
          }
          return null
        }
      } else {
        console.log('Failed to get Firebase token')
      }
    } else {
      console.log('Permission not granted:', permission)
    }
    return null
  } catch (error) {
    console.error('Error in initializeFirebaseMessaging:', error)
    return null
  }
}

// This handles foreground messages
interface FirebaseMessage {
  title: string
  body: string
  data?: any
  url?: string
}

export const onMessageListener = (callback: (message: FirebaseMessage) => void): any => {
  return onMessage(messaging, async (payload) => {
    console.log('Received foreground message:', payload)

    // Refresh notifications using the singleton service
    try {
      const { useNotificationsService } = await import('@/services/notificationsService')
      const notificationsService = useNotificationsService()
      await notificationsService.getNotifications()
      console.log('Notifications refreshed after receiving message')
    } catch (error) {
      console.error('Failed to refresh notifications:', error)
    }

    // Format message based on type
    let formattedMessage: FirebaseMessage

    if (payload.data?.title && payload.data?.body) {
      formattedMessage = {
        title: payload.data.title,
        body: payload.data.body,
        data: payload.data,
        url: payload.data.url
      }
    } else if (payload.notification?.title && payload.notification?.body) {
      formattedMessage = {
        title: payload.notification.title,
        body: payload.notification.body,
        data: payload.data
      }
    } else {
      console.warn('Received message without required title/body')
      return
    }

    // Call callback with formatted message
    callback(formattedMessage)
  })
}
