// Your web app's Firebase configuration -- from firebase console
// these are all public -- needed for SDK to connect to firebase project
export const firebaseConfig = {
  apiKey: 'AIzaSyD6Wcu1a2JfvwHI-ek-cIrznpWXmwCfFS0',
  authDomain: 'x-ray-394814.firebaseapp.com',
  projectId: 'x-ray-394814',
  storageBucket: 'x-ray-394814.firebasestorage.app',
  messagingSenderId: '710004218458',
  appId: '1:710004218458:web:bae360b81dd736ec005663'
}
